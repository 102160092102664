import React from 'react';
import ReactDOM from 'react-dom';
import { Amplify } from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'
import awsConfig from '@alucio/aws-beacon-amplify/src/aws-exports';

import App from './App';

Amplify.configure(awsConfig)
Auth.currentCredentials()
  .then(async d => { })
  .catch(e => console.error('Cognito Failure: ', e))

Amplify.configure({
  aws_appsync_authenticationType: 'AWS_IAM',
});

/** RENDER WEB APP */
ReactDOM.render(<App />, document.getElementById('app'));
