import React, { useState, useEffect } from 'react';
import { saveAs } from 'file-saver';
import { View, StyleSheet, ImageBackground } from 'react-native'
import { Text, luxColors, DNABox, util } from '@alucio/lux-ui';
import AWSConfig from '@alucio/aws-beacon-amplify/src/aws-exports';
import { useParams } from 'react-router';
import { isMobile } from 'react-device-detect';
import API, { graphqlOperation } from '@aws-amplify/api';
import { exchangeContentShareTokenForAccessToken } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import {
  ExchangeContentShareTokenForAccessTokenQuery,
} from '@alucio/aws-beacon-amplify/src/API'
import config from '@alucio/beacon/src/config/app.json';

const styles = StyleSheet.create({
  backgroundImage: {
    flex: 1,
  },
  backgroundOverlay: {
    backgroundColor: luxColors.opacityBackground.primary,
    width: '100%',
    height: '100%',
  },
  mainContainer: {
    flex: 1,
  },
  messageContainer: {
    marginTop: 55,
  },
  headerMessage: {
    fontSize: 32,
    fontWeight: '500',
    color: 'white',
  },
  lineMessage: {
    fontSize: 24,
    color: 'white',
    fontWeight: '600',
    paddingBottom: 20,
    textAlign: 'center',
  },
  lineMessageMobile: {
    fontSize: 18,
    color: 'white',
    fontWeight: '600',
    paddingBottom: 15,
    textAlign: 'center',
  },
});

interface MessageProps {
  headerMessage?: string,
  line1: string,
  line2?: string,
}

function Message(props: MessageProps) {
  const { headerMessage, line1, line2 } = props;
  return (
    <DNABox appearance="col" alignX="center" alignY="center" fit="fill">
      <Text style={styles.headerMessage}>{headerMessage}</Text>
      <DNABox appearance="col" alignX="center" alignY="start" style={[styles.messageContainer]}>
        <Text
          style={util.mergeStyles(undefined,
            [styles.lineMessageMobile, isMobile],
            [styles.lineMessage, !isMobile])}
        >
          {line1}
        </Text>
        <Text
          style={util.mergeStyles(undefined,
            [styles.lineMessageMobile, isMobile],
            [styles.lineMessage, !isMobile])}
        >
          {line2}
        </Text>
      </DNABox>
    </DNABox>
  )
}

async function getContentFromCloudfront(token:string, key:string):Promise<Blob> {
  const bucket = AWSConfig.aws_user_files_s3_bucket;
  const url = `${config.contentURL}/content/${key}`;
  const imgResponse = await fetch(url, {
    method: 'GET',
    headers: {
      'Alucio-Authorization': token,
      'Alucio-Content-Bucket': bucket,
    },
  });
  return await imgResponse.blob();
}

async function downloadContentFromCloudfront(
  token: string,
  url: string,
  fileName: string,
): Promise<void> {
  const content = await getContentFromCloudfront(token, url);
  saveAs(content, fileName);
}

function Home() {
  const { shareToken } = useParams() as { shareToken: string };
  const [headerMessage, setHeaderMessage] = useState('Preparing your download');
  const [line1, setLine1] = useState('Please wait...');

  useEffect(() => {
    async function downloadShareTokenContent() {
      try {
        const {
          data,
        } = (await API.graphql(graphqlOperation(exchangeContentShareTokenForAccessToken, { token: shareToken }))) as {
          data: ExchangeContentShareTokenForAccessTokenQuery
        }
        if (data.exchangeContentShareTokenForAccessToken) {
          setHeaderMessage('Download in progress')
          setLine1('Please wait...')
          const tokenData = data.exchangeContentShareTokenForAccessToken;
          await downloadContentFromCloudfront(tokenData.token, tokenData.key, tokenData.fileName)
          setHeaderMessage('Download complete')
          setLine1('')
        }
      } catch (er) {
        console.error(`Error Occurred Processing Link: ${JSON.stringify(er)}`);
        setHeaderMessage('Unable to process link')
        setLine1('Please contact the sender for an updated link')
      }
    }
    downloadShareTokenContent();
  }, [shareToken]);

  return (
    <View style={styles.mainContainer}>
      <ImageBackground
        source={require('../../../assets/images/sharingPlaceholder.jpg')}
        style={styles.backgroundImage}
      >
        <View style={styles.backgroundOverlay}>
          <Message
            headerMessage={headerMessage}
            line1={line1}
          />
        </View>
      </ImageBackground>
    </View>
  );
}

Home.displayName = 'Home';

export default Home;
