import React from 'react';
import { Route, Switch, Redirect } from './index';

import Home from '../screens/Home/Home';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/share/:shareToken" component={Home} />
      <Route exact path="/">
        <Redirect to="/share/404_invalid_token" />
      </Route>
    </Switch>
  );
};

export default Routes;
