import React from 'react';
import { View } from 'react-native';
import {
  LuxTheming,
  AlucioTheme,
  luxColors,
  responsive,
  useWindowSize,
  withToastProvider,
} from '@alucio/lux-ui';
import { Router } from './router';
import Routes from './router/routes';

const styles = {
  AppContainer: {
    backgroundColor: luxColors.applicationBackground.primary,
    flex: 1,
    height: responsive.dimensions.trueHundredHeight,
  },
};

const App = () => {
  return (
    <Router>
      <Routes />
    </Router>
  );
};

const withProviders = ChildComponent => () => {
  const size = useWindowSize();
  return (
    <View style={[styles.AppContainer, size]}>
      <LuxTheming config={AlucioTheme}>
        <ChildComponent />
      </LuxTheming>
    </View>
  );
};

export default withProviders(withToastProvider(App));
